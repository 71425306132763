import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import LangContext from '../context/LangContext'

class CaseRoll extends React.Component {
  static contextType = LangContext;
  render() {
    const { data } = this.props
    let { edges: posts } = data.allMarkdownRemark

    const { lang } = this.context;
    let langPost = posts.filter(({ node: post }) => post.frontmatter.langKey === lang);

    return (

      <div>
        {/* <div className="tech-case-head">{lang === 'kr' ? "고객 사례": "Case Study"}</div> */}
        <div className="columns is-multiline">
          {langPost &&
            langPost.map(({ node: post }) => (
              <div className="is-parent column is-4" key={post.id} style={{ paddingBottom: "2.5rem" }}>
                <article
                  className={`case-list-item tile is-child box card-view notification ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                    }`}
                >
                  {/* <Link to={post.fields.slug}> */}
                  <header className="view-head">
                    {post.frontmatter.featuredimage ? (
                      <div className="featured-thumbnail">
                        <Link to={post.fields.slug}>
                          <PreviewCompatibleImage
                            imageInfo={{
                              image: post.frontmatter.featuredimage,
                              alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                            }}
                          />
                        </Link>
                      </div>
                    ) : null}
                    <p className="post-meta">
                      <Link
                        className="case-title"
                        to={post.fields.slug}
                      >
                        {post.frontmatter.title}
                      </Link>
                    </p>
                  </header>
                  <div className="view-content">
                    {post.excerpt}
                    <br />
                    <br />
                    <p className="read-date">
                      <Link className="button" to={post.fields.slug}>
                        READ MORE
                  </Link>
                    </p>
                  </div>
                  {/* </Link> */}
                  <Link className="view-link" to={post.fields.slug}>
                    <div className="view-effect" />
                  </Link>
                </article>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

CaseRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query CaseRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "case-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength:130)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                langKey
                date(formatString: "YY-MM-DD")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 330, maxHeight: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <CaseRoll data={data} count={count} />}
  />
)

